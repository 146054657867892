<template>
  <action-dispatcher
    ref="dispatcher"
    action="answers/getAnswer"
    :parameters="$route.params.id"
    @success="success"
  >
    <div class="view-user-answers">
      <div class="d-flex aling-items-center justify-content-end mb-5">
        <h5 class="font-weight-normal flex-fill">
          Prova: <span class="font-weight-bold">{{ moduleName }}</span>
        </h5>
      </div>

      <div class="row">
        <div class="col-lg-3">
          <h6>Pergunta</h6>
        </div>
        <div class="col-lg-3">
          <h6>Respondido</h6>
        </div>
        <div class="col-lg-3">
          <h6>Alternativa Correta</h6>
        </div>
        <div class="col-lg-3">
          <h6>Acertou?</h6>
        </div>
      </div>

      <template v-if="answer">
        <div
          v-for="(answer, i) in answers"
          :key="`answer-${i}`"
          class="row border-bottom py-3"
        >
          <div class="col-lg-3">
            {{ answer.text }}
          </div>
          <div class="col-lg-3">
            {{ getAnswer(answer).text }}
          </div>
          <div class="col-lg-3">
            {{ getAnswerRight(answer).text }}
          </div>
          <div class="col-lg-3">
            <h4 v-if="isRight(answer)"><i class="icon-check"></i></h4>
          </div>
        </div>
      </template>
    </div>
  </action-dispatcher>
</template>

<script>
export default {
  mounted() {
    this.$store.dispatch("questions/get");
  },
  data() {
    return {
      currentModule: null,
      answer: []
    };
  },
  computed: {
    moduleName() {
      if (this.currentModule) return this.currentModule.name;

      return "";
    },
    answers() {
      return this.answer.answers;
    }
  },
  methods: {
    getAnswer(answer) {
      return answer.alternatives.find(a => a.id == answer.answer);
    },
    getAnswerRight(answer) {
      return answer.alternatives.find(a => a.is_right);
    },
    isRight(answer) {
      return this.getAnswer(answer).id == this.getAnswerRight(answer).id;
    },
    success(data) {
      this.answer = data;
      this.$store
        .dispatch("content/getModule", this.answer.module)
        .then(data => {
          this.currentModule = data;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.view-user-answers {
  .card {
    flex-direction: row;
    align-items: center;
    justify-content: center;

    h6,
    p {
      font-size: 14px;
      font-weight: normal;

      span {
        font-weight: bold;
      }
    }
  }
}
</style>
